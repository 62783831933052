import path from "path"
import { createUrl, PathRegExp } from "@marvinh/path-to-regexp"

import type { Nullish, ParseUrlParams } from "~/@types/generics"
import pathsMapping from "~/config/paths-mapping.json"
import { DEFAULT_LANG, DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { getLang } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { isLocale } from "~/lib/i18n/utils/is-locale"
import { getPropertyFromGID } from "~/lib/shopify/utils/id"
import { SB_PAGES, type SbPagesComponent } from "~/lib/storyblok/constants"
import type { SbLinkFromSchema, ShopifyLinkFromSchema } from "~/components/ui/Link/_data/schema"
import { isSbLink, isShopifyLink } from "~/components/ui/Link/utils/is-link"
import { objectKeys } from "~/utils/object-keys"

export const isProductPage = "products"

export function hrefResolver(link: SbLinkFromSchema | ShopifyLinkFromSchema, locale: Nullish<string>) {
  const processedLocale = getProcessedLocale(locale)

  if (isShopifyLink(link)) {
    const property = getPropertyFromGID(link.id)
    switch (property) {
      case "Product":
        return getPath(processedLocale, "products/:slug", { slug: link.handle })
      case "Collection":
        return getPath(processedLocale, "collections/:slug", { slug: link.handle })
      default:
        return null
    }
  }

  if (isSbLink(link)) {
    if (!link.story) {
      return null
    }

    const translatedSlug = getLocalizedStorySlug(link, locale)
    const pageComponent = getStoryComponent(link, locale)

    let processedSlug = link.story.slug

    switch (pageComponent) {
      case "home_page":
        return getPath(processedLocale, "/", {})
      case "universal_page":
        processedSlug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "pages/:slug", { slug: processedSlug })
      case "collection_page":
        processedSlug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "collections/:slug", { slug: processedSlug })
      case "legals_page":
        processedSlug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "legals/:slug", { slug: processedSlug })
      case "blog_home_page":
        return getPath(processedLocale, "le-journal", {})
      case "blog_article_page":
        processedSlug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "le-journal/:slug", { slug: processedSlug })
      case "login_page":
        return getPath(processedLocale, "account/login", {})
      case "register_page":
        return getPath(processedLocale, "account/register", {})
      case "enable_account_page":
        return getPath(processedLocale, "account/enable-account", {})
      case "reset_password_page":
        return getPath(processedLocale, "account/reset-password", {})
      case "forgot_password_page":
        return getPath(processedLocale, "account/forgot-password", {})
      case "account_addresses_page":
        return getPath(processedLocale, "/account/addresses", {})
      case "account_informations_page":
        return getPath(processedLocale, "/account", {})
      case "account_orders_page":
        return getPath(processedLocale, "/account/orders", {})
      case "search_page":
        return getPath(processedLocale, "/search", {})
      default:
        return null
    }
  }
}

export function getPath<Route extends string, Params extends ParseUrlParams<Route>>(
  locale: Nullish<string>,
  route: Route,
  params: Params
) {
  const processedLocale = isLocale(locale) ? locale : DEFAULT_LOCALE

  const processedRoute =
    (pathsMapping as Record<string, Record<string, { source: string; destination: string }>>)?.[route]?.[
      processedLocale
    ]?.source ?? route

  const pathRegExp = new PathRegExp(processedRoute)

  const endPath = createUrl(pathRegExp, params)

  return path.join("/", processedLocale, endPath)
}

function getStoryComponent(link: SbLinkFromSchema, locale: Nullish<string>) {
  if (link?.story?.content?.component) {
    return link?.story?.content?.component as SbPagesComponent
  }

  if (!link?.story?.full_slug) return null

  const slug = removeLangFromSlug(link?.story?.full_slug, locale)

  if (!slug) return null

  return findComponentFromFullSlug(slug)
}

function getLocalizedStorySlug(link: SbLinkFromSchema, locale: Nullish<string>) {
  if (link?.story?.content) {
    return (
      link?.story?.translated_slugs?.find((slug) => slug?.lang === getLang(locale)) ?? {
        path: link?.story?.default_full_slug ?? removeLangFromSlug(link?.story?.full_slug, locale),
      }
    )
  }

  return { path: removeLangFromSlug(link?.story?.full_slug, locale) }
}

function removeLangFromSlug(slug: Nullish<string>, locale: Nullish<string>) {
  const cmsLang = getLang(locale)
  return cmsLang !== DEFAULT_LANG ? slug?.replace(`${cmsLang}/`, "") : slug
}

/**
 * Finds the most relevant component key from a cached URL.
 *
 * This function iterates over the keys of the SB_PAGE_STORIES object and checks if the cached URL starts with the rootSlug value of each key.
 * It keeps track of the key with the longest matching rootSlug and the deepest path (most slashes).
 *
 */
function findComponentFromFullSlug(fullSlug: Nullish<string>) {
  if (!fullSlug) {
    return undefined
  }

  const keys = objectKeys(SB_PAGES)

  // Initialize variables to keep track of the most relevant key, maximum match length, and maximum depth
  let mostRelevantKey: SbPagesComponent | undefined
  let maxMatchLength = 0
  let maxDepth = 0

  for (const key of keys) {
    // Get the rootSlug value for the current key
    const value = SB_PAGES[key].slug.replace(":slug", "")

    // Check if the cached URL starts with the current rootSlug value
    if (fullSlug.startsWith(value)) {
      // Calculate the length of the matching part
      const matchLength = value.length
      // Calculate the depth of the current rootSlug by counting the number of slashes
      const depth = (value.match(/\//g) || []).length

      // Update the most relevant key if the current rootSlug has a greater depth
      // or if it has the same depth but a longer match length
      if (depth > maxDepth || (depth === maxDepth && matchLength > maxMatchLength)) {
        mostRelevantKey = key
        maxMatchLength = matchLength
        maxDepth = depth
      }
    }
  }

  // Return the most relevant key or undefined if no match is found
  return mostRelevantKey
}
