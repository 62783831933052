export const COOKIE_KEYS = {
  newsletterPopIn: (locale: string) => `newsletter-pop-in_${locale}`,
  newsletterPopInAttempts: (locale: string) => `newsletter-pop-in_attempts_${locale}`,
  uspBanner: (locale: string) => `usp-banner_${locale}`,
  nextLocale: "NEXT_LOCALE",
  popinMarketHasInteracted: "popin-market-has-interacted",
  popinMarketCountryCode: "popin-market-country-code",
  releaseId: "release-id",
  vercelCountryCode: "VERCEL_COUNTRY_CODE",
  IS_DRAFT_MODE: "isDraftMode",
}
